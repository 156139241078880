body {
    background-color: #eee;

}

#root {
    display: flex;
    flex-direction: row;

    .App {
        //width: 100%;
        max-width: 800px;
        margin: 10px auto 0px auto;
        padding: 90px;
        text-align: center;
        background-color: white;
        border: 1px solid #aaa;
        box-shadow: 0px 0px 10px #ccc;


    }
}